
<template>
  <div @copy="disableCopyPaste" @paste="disableCopyPaste">
    <h2>{{ table_title[0] }}</h2>
    <h2>{{ second_name[0] }}</h2>
    <div class="topHead">
      <table id="t1">
        <tr>
          <th v-for="(header, index) in topData[0]" :key="index">
            {{ header }}
          </th>
          <!-- <th v-for="(header, index) in topData[0]" :key="index">
            {{ header }}
          </th> -->
        </tr>
        <tr>
          <th v-for="(header, index) in topData[1]" :key="index">
            {{ header }}
          </th>
          <!-- <th v-for="(header, index) in topData[2]" :key="index">
            {{ header }}
          </th> -->
        </tr>
        <tr>
          <th v-for="(header, index) in topData[3]" :key="index">
            {{ header }}
          </th>
          <!-- <th v-for="(header, index) in topData[4]" :key="index">
            {{ header }}
          </th> -->
        </tr> 
      </table>
    </div>
    <div class="tb_btn">
      <button @click="clear_all">重置</button>
      <input type="text" v-model="filterKeyword" placeholder="系列/型号" />
      <button @click="filterTable">筛选</button>
      <input type="text" v-model="autoNum" placeholder="自增/自减" />
      <button @click="autoChange">确定</button>
    </div>
    <div class="container">
      <table id="t2">
        <thead>
          <tr>
            <th class="fixed-column">序号</th>
            <th class="fixed-column">规格</th>
            <th
              v-for="(header, index) in tableData[0]"
              :key="index"
              @click="selectColumn(header)"
            >
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in tableData.slice(1)"
            :key="index"
            :class="index % 2 === 0 ? 'even' : 'odd'"
          >
            <td v-for="item in row" :key="item">{{ item }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, toRefs } from "vue";

export default {
  created() {
    document.title = "Loading...";
  },
  // 禁用复制粘贴
  methods: {
    disableCopyPaste(event) {
      event.preventDefault();
      // 可以选择在需要时显示提示信息
      alert("复制粘贴功能已禁用！");
    },
  },
  setup() {
    const state = reactive({
      filterKeyword: "", // 过滤关键字
      autoNum: "", // 自增自减数值
    });

    let filterKeyword = reactive(""); // 过滤关键字

    console.log("关键字", state.filterKeyword);

    function filterTable() {
      if (
        typeof state.filterKeyword != "undefined" &&
        state.filterKeyword != "undefined"
      ) {
        console.log("key", state.filterKeyword);
        localStorage.setItem("filterKeyword", state.filterKeyword);
        window.location.reload();
      }
    }

    function clear_all() {
      // 清空 localStorage 中的所有数据
      localStorage.clear();
      window.location.reload();
    }

    let autoNum = localStorage.getItem("autoNum");
    function autoChange() {
      // 自动增减数据
      console.log("自动增改数据", state.autoNum, typeof state.autoNum);
      localStorage.setItem("autoNum", state.autoNum);
      window.location.reload();
    }
    const tableData = reactive([]); // 处理好的数据表格
    const topData = reactive([]); // 顶部数据
    const date = reactive([]); // 文件的名字
    const company_name = reactive([]); // 公司的名字
    const second_name = reactive([]); // 小标题
    const table_title = reactive([]);
    const finalData = {}; // 系列+型号作为key 价格为value 存放数据集

    function selectColumn(header) {
      localStorage.setItem("showColumn", header);
      window.location.reload();
    }

    const guige = new Set();
    const xilie = new Set();

    const hulu = ["架空绝缘电缆"];

    async function loadTxtData() {
      try {
        let response = await fetch("长江现货.txt"); // 替换为您的文件路径
        let csvText = await response.text();
        let temp = csvText.trim();
        temp = temp.split("\n");
        let table_head = temp[0].split(" ");
        table_head.splice(4, 1); // 移除单位
        topData.push(table_head); // 表头
        // 获取当前日期
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
        const day = currentDate.getDate().toString().padStart(2, "0");
        const dateString = `${year}-${month}-${day}`;

        for (let item of temp.slice(1)) {
          let t_d = item.split("\t");
          if (t_d[t_d.length - 1].length < 7) {
            t_d[t_d.length - 1] = dateString;
          }
          t_d.splice(4, 1);
          topData.push(t_d);
        }
        console.log(topData);
      } catch (error) {
        console.error("无法读取或解析 txt 文件:", error);
      }
    }

    async function loadCsvData() {
      try {
        let response = await fetch("name.txt"); // 替换为您的 CSV 文件路径
        let csvText = await response.text();
        let temp = csvText.trim();
        temp = temp.split(",");
        let filename = temp[0];
        console.log(temp);
        company_name.push(temp[1]);
        table_title.push(temp[2]);
        second_name.push(temp[3]);
        document.title = company_name;
        console.log(second_name[0]);
        // console.log("公司：", second_name);
        console.log("table title", table_title);
        console.log("文件名:", filename);

        // 从文件名获取年月日
        let t = filename.replace(".csv", "").split("-");
        date.push(t[0]);
        date.push(t[1]);
        date.push(t[2]);

        response = await fetch(filename); // 替换为你的 CSV 文件路径
        let arrayBuffer = await response.arrayBuffer();
        csvText = decodeCSV(arrayBuffer, "utf-8"); // 指定编码方式
        // console.log(csvText);
        let rows = csvText.split("\n");

        for (let i = 1; i < rows.length; i++) {
          const row = rows[i].split(",");
          // 取出每一行的具体型号
          const str = row[2];
          // 取出系列
          const x = row[0];
          // 判断系列是否需要忽略
          if (hulu.indexOf(x) == "-1") {
            // console.log("不忽略");
            if (typeof str !== "undefined") {
              let gui = "";
              let xi = "";
              const splitIndex = str.lastIndexOf("-");

              if (splitIndex < 0) {
                xi = str.match(/([0-9.]+)\*(.*)/g)[0];
                gui = x + str.replace(xi, "");
                // console.log(xi);
              } else {
                gui = x + str.substring(0, splitIndex);
                xi = str.substring(splitIndex + 1);
                // console.log(xi);
              }
              gui = gui.trim();
              xi = xi.trim();
              // console.log("gui xi", gui, xi);
              // 如果存在关键字就判断
              if (
                typeof filterKeyword != "undefined" &&
                filterKeyword.length != null &&
                filterKeyword != "undefined"
              ) {
                let keyword = filterKeyword.trim().toLowerCase(); // 转换为小写
                if (gui.toLowerCase().includes(keyword)) {
                  guige.add(gui);
                  xilie.add(xi);
                  let t_sum = row[row.length - 1];
                  if (autoNum != null) {
                    t_sum = (
                      (parseFloat(t_sum) * (100 + parseFloat(autoNum))) /
                      100
                    ).toFixed(2);
                  }
                  finalData[gui + xi] = t_sum;
                }
              } else {
                guige.add(gui);
                xilie.add(xi);
                finalData[gui + xi] = row[row.length - 1];
              }

              // console.log("length", row.length)
            }
          }
        }
        // 排序
        const sortedXilie = Array.from(xilie).sort((a, b) => {
          let numa = a.split("*");
          let numb = b.split("*");

          for (let i = 0; i < numa[0].length; i++) {
            let result = 0;
            let num1 = parseFloat(numa[i]);
            let num2 = parseFloat(numb[i]);
            if (num1 !== num2) {
              result = num1 < num2 ? -1 : 1;
              return result;
            }
          }
        });
        let showColumn = localStorage.getItem("showColumn");
        console.log("只显示的列", showColumn, typeof showColumn);
        if (showColumn != null) {
          guige.clear();
          guige.add(showColumn);
        }
        // 初始化第一行标题
        const initRow = [];
        for (const item of guige) {
          initRow.push(item);
        }
        tableData.push(initRow);

        let index = 0;
        sortedXilie.forEach((xi) => {
          const row = [];
          let sign = 1;
          row.push(index + 1);
          row.push(xi);

          for (const gui of guige) {
            const t = gui + xi;

            const value = finalData[t] || 0;
            // console.log(value, typeof(value));
            if (showColumn != null) {
              if (value == 0) {
                sign = 0;
              }
            }
            row.push(value);
          }
          if (sign == 1) {
            tableData.push(row);
            index++;
          }
        });

        // if (showColumn == null) {
        //     t_data = t_data.filter(item => item.value !== 0)
        // }
      } catch (error) {
        console.error("无法读取或解析 CSV 文件:", error);
      }
    }

    // 编码
    function decodeCSV(data, encoding) {
      const decoder = new TextDecoder(encoding);
      const decodedData = decoder.decode(data);
      return decodedData;
    }

    onMounted(() => {
      loadCsvData();
      loadTxtData();
      if (localStorage.getItem("filterKeyword")) {
        filterKeyword = localStorage.getItem("filterKeyword");
        console.log("从本地读取的过滤关键字", filterKeyword);
      }
    });

    return {
      ...toRefs(state),
      tableData,
      date,
      filterTable,
      table_title,
      second_name,
      company_name,
      selectColumn,
      clear_all,
      autoChange,
      topData,
    };
  },
};
</script>

<style lang="less">
.even {
  background-color: rgba(
    179,
    179,
    179,
    0.267
  ) !important; /* 偶数行的背景颜色，透明度为 0.5 */
}

.odd {
  background-color: #ffffff !important; /* 奇数行的背景颜色 */
}
table {
  width: 100%;
  margin: auto;
  border-collapse: collapse;
}
.container {
  width: 99%;
  height: 650px; /* 表格容器的高度，超出范围时会出现滚动条 */
  overflow: auto; /* 显示滚动条 */
  margin: 0 auto;
}

#t2 {
  td,
  th {
    height: 30px; /* 设置每行的高度为*/
    min-width: 150px;
    border: 1px solid #fff; /* 使用白色(#fff)的边框 */
    padding: 8px;
  }

  thead th {
    height: 40px;
    position: sticky; /* 将表头设置为固定位置 */
    top: 0;
    background-color: #7d92f1; /* 设置表头的背景颜色 */
  }
  td:nth-child(1) {
    min-width: 70px;
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: #f5f5f5;
  }
  td:nth-child(2) {
    min-width: 70px;
    position: sticky;
    left: 87px;
    z-index: 1;
    background-color: #f5f5f5;
  }

  th.fixed-column:first-child {
    min-width: 70px;
    position: sticky;
    left: 0;
    z-index: 2;
    background-color: #7d92f1; /* 设置表头的背景颜色 */
  }

  th.fixed-column:nth-child(2) {
    min-width: 70px;
    position: sticky;
    left: 87px;
    z-index: 2;
    background-color: #7d92f1; /* 设置表头的背景颜色 */
  }
}

.tb_btn {
  // margin-left: 53%;
  margin-bottom: 10px;
  margin-top: 10px;
  height: 30px;
  button {
    margin-left: 12px;
    height: 100%;
    color: rgb(35, 35, 37);
  }

  input {
    margin-left: 12px;
    height: 80%;
    width: 18%;
  }
}
.topHead {
  width: 30%;
  font-size: 10px; /* 使用像素作为单位 */
  margin: 0 auto;
  padding: 0;
}
/* 在小屏幕上将表格占满宽度 */
@media screen and (max-width: 767px) {
  #t1 {
    margin: 0; /* 取消左右外边距，让表格占满宽度 */
    th{
      min-width: 20px;
    }
  }
  .topHead {
    // font-size: 8px; /* 使用像素作为单位 */
    width: 98%;
  }
}

#t1 {
  td {
    width: 5%;
    height: 20px;
    background-color: white;
  }
}
</style>